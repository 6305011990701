import axios from "axios";

export const client = axios.create({
  baseURL: "https://pm-backend-p2t2qinwsa-uc.a.run.app",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=UTF-8",
  },
  timeout: 1000 * 60,
});
export default client;
