import React from "react";
import ipad from "../assets/ipad.svg";
import './index.css'

const Card = ({ imageUrl, title, url, shouldShowIcon }) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="relative rounded-md bg-[rgba(0,0,0,0.23)] w-[220px] h-[162px] overflow-hidden px-[40px] py-[47px] "
    >
      {shouldShowIcon && (
        <div className="absolute flex top-2 right-2 text-white opacity-80 gap-1  ">
          <img src={ipad} alt="Card-image" className=" w-4 h-4" />
          <span> متوفر للآيباد</span>
        </div>
      )}
      <div className="flex flex-col items-center gap-4 justify-center ">
        <div>
          <img src={imageUrl} alt="Card-image" className="" />
        </div>
        <div>
          <h3>{title}</h3>
        </div>
      </div>
    </a>
  );
};

export default Card;
