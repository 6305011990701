import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import { toast } from "react-toastify";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const authContext = useContext(AuthContext);
  const [showForm, setShowForm] = useState(false);

  const handleForm = () => {
    setShowForm(!showForm);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await authContext.login({ username, password });
    } catch (error) {
      toast.error("البريد الالكتروني او كلمه رقم السري خطآ");
    }
  };

  return (
    <div className="bg-images h-screen bg-cover bg-no-repeat ">
      {showForm && (
        <div className="border border-white bg-[rgba(0, 0, 0, 0.4)] backdrop-blur-sm w-[450px] h-[300px] rounded-3xl  absolute bottom-32 right-10 shadow-[#d9d6cd 1px 1px 3px] ">
          <form
            onSubmit={handleSubmit}
            className=" mt-7 flex flex-col justify-start mx-5"
          >
            <div className="mb-4  ">
              <label className="text-white text-right block mb-2">
                البريد الالكتروني
              </label>
              <input
                className=" w-full p-2 bg-transparent border rounded-full"
                placeholder="أدخل بريدك الالكتروني"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                dir="rtl"
              />
            </div>
            <div className="">
              <label className="text-white text-right block mb-2">
                الرقم السري
              </label>
              <input
                className=" w-full p-2 bg-transparent border rounded-full"
                type="password"
                value={password}
                placeholder="أدخل كلمة المرور"
                onChange={(e) => setPassword(e.target.value)}
                dir="rtl"
              />
            </div>
            <button
              type="submit"
              className="bg-transparent border rounded-full cursor-pointer text-white text-xl  mt-10  w-full p-2"
            >
              تسجيل الدخول
            </button>
          </form>
        </div>
      )}

      <button
        type="submit"
        className="bg-transparent border rounded-full cursor-pointer text-white text-xl  absolute bottom-10 right-10 w-40  py-2 px-5"
        onClick={handleForm}
      >
        الدخول
      </button>
    </div>
  );
};

export default Login;
