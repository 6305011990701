import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Admin from "./page/Admin";
import Login from "./page/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate replace to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<Admin />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
// return (
//   <BrowserRouter>
//     <Routes>
//       <Route path={ROUTES.LOGIN} element={<Login />} />
//       <Route path="/" element={<AuthRoute />}>
//         <Route path={ROUTES.ADMIN_HOME} element={<Admin />} />
//       </Route>
//     </Routes>
//   </BrowserRouter>
// );
