import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import client from "../helpers/clients";
import { ADMIN_HOME, LOGIN } from "../utils/routes";
import { toast } from "react-toastify";

export const AuthContext = createContext({
  user: null,
  setUser: () => {},
  token: null,
  setToken: () => {},
  login: () => {},
  logout: () => {},
  checkLogin: () => {},
  loading: true,
  updateLoading: true,
  setLoading: () => {},
});

export default function AuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const login = useCallback(async ({ username, password }) => {
    setLoading(true);
    await client
      .post("/login", {
        username,
        password,
      })
      .then((response) => {
        const result = response.data;
        let exist = false;
        if (response.data.Authorization === result.Authorization) {
          exist = true;
          setUser({ ...result });
          localStorage.setItem("token", result.Authorization);
          window.location.pathname = ADMIN_HOME;
        }
        if (!exist) {
          toast.error("الايميل او الرقم السري خظا");
        }
      })
      .catch((error) => {
        const errorMessage = error.response.data;
        if (errorMessage && errorMessage.message) {
          toast.error(errorMessage.message, { autoClose: 3000 });
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // try {
    //   const response = await client.post("/login", {
    //     username,
    //     password,
    //   });
    //   if (response.data.Authorization) {
    //     setUser(response.data);
    //     localStorage.setItem("token", response.data.Authorization);
    //     window.location.pathname = ADMIN_HOME;
    //   } else {
    //     toast.error("Invalid email or password");
    //   }
    // } catch (error) {
    //   console.error(
    //     "Login error:",
    //     error.response ? error.response.data : error.message
    //   );
    //   toast.error(error.response?.data?.message || "Login failed", {
    //     autoClose: 1000,
    //   });
    // } finally {
    //   setLoading(false);
    // }
  }, []);

  const logout = useCallback(async () => {
    localStorage.removeItem("token");
    setUser(null);
    delete client.defaults.headers.common.Authorization;
    if (window.location.pathname !== LOGIN) {
      window.location.pathname = LOGIN;
    }
  }, []);

  // const checkLogin = useCallback(async () => {
  //   const token = localStorage.getItem("token");
  //   if (token) {
  //     setLoading(true);
  //     try {
  //       // Sending token to the login endpoint for validation
  //       const response = await client.post("/login", { token });
  //       const user = response.data;

  //       if (response.data.Authorization === user.Authorization) {
  //         setUser(user);
  //         if (window.location.pathname === LOGIN) {
  //           window.location.pathname = ADMIN_HOME;
  //         }
  //       } else {
  //         throw new Error("Invalid token");
  //       }
  //     } catch (error) {
  //       console.error("Error in checkLogin:", error);
  //       localStorage.removeItem("token");
  //       setUser(null);
  //       window.location.pathname = LOGIN;
  //     } finally {
  //       setLoading(false);
  //     }
  //   } else {
  //     window.location.pathname = LOGIN;
  //   }
  // }, []);

  // useEffect(() => {
  //   if (!user) {
  //     checkLogin();
  //   }
  // }, [user]);

  const values = useMemo(
    () => ({
      user,
      token,
      setToken,
      setUser,
      login,
      logout,
      // checkLogin,
      loading,
      setLoading,
      updateLoading,
      setUpdateLoading,
    }),
    [user, token, loading]
  );

  return (
    <AuthContext.Provider value={values}> {children} </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within AuthContextProvider");
  }
  return context;
}
