import React, { useContext } from "react";
import Card from "../component/Card";
import rfp from "../assets/home/01.svg";
import dms from "../assets/home/02.svg";
import pm from "../assets/home/03.svg";
import d3 from "../assets/home/04.svg";
import media from "../assets/home/05.svg";
import ipad from "../assets/ipad.svg";
import Logo from "../assets/logo.svg";
import { AuthContext } from "../context/authContext";

const Admin = () => {
  const { logout } = useContext(AuthContext);
  const cardData = [
    {
      title: "انتاج الكراسات",
      imageUrl: rfp,
      url: "https://rfp-dev.vara360.com/",
    },
    {
      title: "إدارة الوثائق",
      imageUrl: dms,
      url: "https://dms-testing.vara360.com/",
    },
    {
      title: "تتبع المشاريع",
      imageUrl: pm,
      url: "https://pm-testing.vara360.com/projects",
    },
    {
      title: "الرسوم الثلاثية ",
      imageUrl: d3,
      url: "https://3D.vara360.com/",
    },
    {
      title: "المنصة الاعلامية",
      imageUrl: media,
      url: "http://85.208.48.114:8083",
    },
  ];
  const shouldShowIcon = (title) => {
    return title === "المنصة الإعلامية" || title === "الرسوم الثلاثية";
  };

  return (
    <>
      <div className="bg-image bg-cover bg-no-repeat min-h-screen lg:bg-cover">
        <div className="absolute top-8 left-8">
          <img src={Logo} />
        </div>
        <div className="container mx-auto flex justify-between h-[100vh] items-center">
          <div className="flex flex-wrap w-6/12">
            <h1 className="text-6xl flex flex-col mb-12 font-bold drop-shadow-xl relative after:bg-[#DDAA1F] after:w-12 after:h-4 after:bottom-[-3rem] after:absolute">
             <span className="block mb-3 font-normal">
                منصة
              </span> 
              إدارة المشاريع
            </h1>
            <p className="w-[70%] mt-8 text-[1.2rem] drop-shadow-xl font-normal">
              هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.
            </p>
          </div>
          <div className="flex flex-wrap w-6/12 gap-4">
            {cardData.map((card, index) => (
              <Card
                key={index}
                {...card}
                shouldShowIcon={shouldShowIcon(card.title)}
              />
            ))}
          </div>
        </div>
        <div>
          <button
            type="submit"
            className="bg-transparent border rounded-full cursor-pointer text-white text-xl  absolute bottom-10 left-10 w-48 py-2 px-5"
            onClick={logout}
          >
            تسجيل الخروج
          </button>
        </div>
      </div>
    </>
  );
};

export default Admin;
